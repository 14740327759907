<template>
  <v-container fluid class="login-bkg height-100">
    <v-overlay v-model="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-overlay>
    <v-row :class="$vuetify.breakpoint.xs ? 'pt-75' : 'pt-150'">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="10" md="7" lg="5" xl="4">
        <v-card tile>
          <v-system-bar
            height="60"
            color="primary"
            class="white--text elevation-5"
          >
            <v-card-title>
              <h3>Forgot Password</h3>
            </v-card-title>
          </v-system-bar>
          <v-system-bar height="5" color="accent" class=""></v-system-bar>
          <v-card-text>
            <span class="text-body-1 text--primary">
              Enter your email. If it matches an account, we will send you a
              password reset email.
            </span>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <div class="pa-4">
              <v-form ref="form" v-model="valid" @submit.prevent="sendEmail()">
                <v-text-field
                  v-model="email"
                  label="Email"
                  dense
                  class="pb-1"
                  :rules="emailRules"
                  type="text"
                ></v-text-field>
                <div class="d-flex justify-center" v-if="errorText !== ''">
                  <p class="error--text text-body-1 font-weight-medium">
                    {{ errorText }}
                  </p>
                </div>
                <div class="d-flex justify-center align-center pt-4">
                  <v-btn
                    type="submit"
                    :disabled="loading"
                    color="primary"
                    class="px-4"
                  >
                    Send Email
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-card-text>
          <v-card-text class="py-4">
            <span class="text-body-1 text--primary">{{ message }}</span>
          </v-card-text>
           <div class="pb-4">
            <v-btn text @click="navTo('/Login')">
              <v-icon>mdi-arrow-left</v-icon>
              Login
            </v-btn>
          </div>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import * as login from "../../api/LoginApi";

// @ is an alias to /src
export default {
  name: "ForgotPassword",
  data: () => ({
    loading: false,
    valid: true,
    email: "",
    errorText: "",
    message: "",
    emailRules: [(v) => !!v || "E-mail is required"],
  }),
  async mounted() {},
  methods: {
    ...mapActions("auth", ["logUserIn"]),
    hardNavTo(href) {
      window.location.href = href;
    },
    navTo(href) {
      this.$router.push({ path: href });
    },
    async sendEmail() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      this.loading = true;
      try {
        this.message = "Sending Forgot Password Email...";
        await login.SendResetEmail(this.email);
        this.message =
          `If there is a user account matching '${this.email}' in our system, we have sent a Reset Password email.`;
        this.email = "";
        this.$refs.form.reset();
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.pt-150 {
  padding-top: 150px;
}

.pt-75 {
  padding-top: 75px;
}
</style>
